<template>
  <div>
      <div class="mt-2">
                    <h3 class="mt-2">Datos personales</h3>
                    
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="name">Nombre</label>
                                <input type="text" name="name" id="name" class="form-control" v-model="persona.nombre" :class="formVal.nombre">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="lastname">Apellido Paterno</label>
                                <input type="text" class="form-control" id="lastname" v-model="persona.paterno" :class="formVal.paterno">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="lastname-mother">Apellido Materno</label>
                                <input type="text" class="form-control" id="lastname-mother"  v-model="persona.materno" :class="formVal.materno">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="sex">Sexo</label>
                                <select name="sex" id="sex" class="form-control" v-model="persona.sexo">
                                    <option value="M">Masculino</option>
                                    <option value="F">Femenino</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="passport">DNI / CI / RUT</label>
                                <input type="text" class="form-control" id="passport"  v-model="persona.dni" :class="formVal.dni">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="birthday">Fecha de nacimiento</label>
                                <v-menu
                                    v-model="showCalendar"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                       <input type="date" class="form-control" id="birthday" v-model="persona.fecha" v-on="on" v-bind="attrs" readonly  :class="formVal.fecha" :max="formVal.minfecha">  
                                    </template>
                                    <v-date-picker v-model="persona.fecha" @input="showCalendar = false" :max="formVal.minFecha"></v-date-picker>
                                </v-menu>
                            
                            
                            </div>
                            <div class="form-group col-md-6">
                                <label for="religion">Religión</label>
                                <select name="religion" id="religion" class="form-control" v-model="persona.religion"> 
                                    
                                    <option value="CATOLICO">Católico</option>
                                    <option value="ADVENTISTA">Adventistas del Séptimo Día</option>
                                    <option value="Anglicana">Anglicana</option>
                                    <option value="Mormon">Mormon</option>
                                    <option value="Bautista">Bautista</option>
                                    <option value="Calvinista">Calvinista</option>
                                    <option value="Bethren">Bethren</option>
                                    <option value="Iglesia de Dios">Iglesia de Dios</option>
                                    <option value="Hermanos Moravos">Hermanos Moravos</option>
                                    <option value="Luterana">Luterana</option>
                                    <option value="Metodistas">Metodistas</option>
                                    <option value="Metodista Pentecostal">Metodista Pentecostal</option>
                                    <option value="Pentecostal">Pentecostal</option>
                                    <option value="Testigos de Jehová">Testigos de Jehová</option>
                                    <option value="Iglesia Unida">Iglesia Unida</option>
                                    <option value="OTRO">NO ESPECIFICAR</option>
                                    <option value="NO">SIN RELIGION</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="pais">País de residencia</label>
                                <select name="pais" id="pais" class="form-control" v-model="persona.pais">
                                    <option value="PER">PERU</option>
                                    <option value="ARG">ARGENTINA</option>
                                    <option value="BOL">BOLIVIA</option>
                                    <option value="BOL">BRASIL</option>
                                    <option value="CHL">CHILE</option>
                                    <option value="ECU">ECUADOR</option>
                                    <option value="PRY">PARAGUAY</option>
                                    <option value="URY">URUGUAY</option>
                                    <option value="X">OTRO</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="ciudad">Ciudad</label>
                                <input type="text" name="ciudad" id="ciudad" class="form-control" v-model="persona.ciudad"  :class="formVal.ciudad">
                            </div>
                        </div>
                        <div class="form-row">
                            
                            <div class="form-group col-md-12">
                                <label for="grado">Grado de instrucción</label>
                                <select name="grado" id="grado" class="form-control" v-model="persona.grado">
                                    <option value="0">SIN ESPECIFICAR</option>
                                    <option value="1">INICIAL</option>
                                    <option value="2">PRIMARIA</option>
                                    <option value="3">SECUNDARIA</option>
                                    <option value="4">SUPERIOR</option>
                                    <option value="5">UNIVERSITARIA</option>
                                </select>
                            </div>
                        </div>

                        <h5 class="pt-2">Credenciales</h5>

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="email">Correo electrónico</label>
                                <input :class="{'border-danger':!validateEmail}" type="email" name="email" id="email" class="form-control" v-model="persona.correo">
                                <div v-if="!validateEmail" class="text-danger text-sm">Correo no válido</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="pass1">Contraseña</label>
                                <input type="password" class="form-control" :class="{'border-danger':!validatePass}" id="pass1"  v-model="persona.password" >
                                <div  v-if="!validatePass" class="text-danger text-sm">Revisar la contraseña</div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="pass2">Confirmar contraseña</label>
                                <input type="password" class="form-control" :class="{'border-danger':!validatePass}" id="pass2" v-model="cpass">
                                <div  v-if="!validatePass" class="text-danger text-sm">Revisar la contraseña</div>
                            </div>
                            
                        </div>

                        <div class="mt-4 mb-4">
                            <div class="h4 text-center">Consentimiento Informado</div>
                            <div class="text-justify">
                                Su participación en el <b>Programa Vida Saludable Virtual</b>  es un acto voluntario. <br /> 
                                <span class="text-success green--text">Beneficios</span>:  Los resultados generados por el sistema son orientaciones individuales de salud, 
                                que podrá ayudar a mejoras su estilo de vida y contribuir con estadísticas científicas con sus resultados, 
                                respetando el anonimato de los participantes. <br />
                                <span class="red--text">Riesgos</span>: Al ingresar al sistema sus datos, de su estado de salud, serán protegidos.  
                                <b>Esto no sustituye a la consulta médica, por lo tanto, en caso sea necesario, recomendamos consultar con su médico. </b>
                            </div>
                        </div>
                        <div class="text-center">
                            <v-btn class="mt-1" color="secundary" small outlined elevation="2" rounded @click="$route.path('/hola')">No acepto las condiciones</v-btn>
                            <v-btn class="mt-1" color="primary" small :disabled="!(validatePass && validateEmail)" rounded @click="registrar()"><v-icon left>mdi-check</v-icon> Acepto las condiciones</v-btn>
                    
                        </div> 
                    </form>
                </div>
  </div>
</template>

<script>
import moment from 'moment'
import Persona from '@/model/app/core/Persona.js'
import securityService from '@/service/hopeSeguridad'
export default {
    data(){return {
        showCalendar: false,
        cpass:'',
        validatePass:false,
        validateEmail:false,
        loginForm:{
            usuario:'',
            contra: ''
        },
        formVal:{
            nombre:'',
            paterno:'',
            materno:'',
            dni:'',
            fecha:'',
            ciudad:'',
            minfecha: moment().subtract(21, 'years').format('YYYY-MM-DD')
            
        },
        persona: new Persona(),
        errorLogin:''
    }},
    mounted(){
        this.persona.fecha = moment().subtract(21, 'years').format('YYYY-MM-DD');
    },
    methods:{
        validarPass(){
            if(this.cpass.length < 3){
                return false;
            }
            if(this.cpass !== this.persona.password){
                return false;
            }
            return true;
        },
        validateForm(){
            
            let trabas = 0;

            this.formVal.nombre = '';
            if(this.persona.nombre < 3){
                this.formVal.nombre = 'is-invalid'; trabas++
            }else{
                this.formVal.nombre = 'is-valid'
            }

            this.formVal.paterno = '';
            if(this.persona.paterno.length < 3){
                this.formVal.paterno = 'is-invalid'; trabas++
            }else{
                this.formVal.paterno = 'is-valid'
            }

            this.formVal.materno = '';
            if(this.persona.materno.length < 3){
                this.formVal.materno = 'is-invalid'; trabas++
            }else{
                this.formVal.materno = 'is-valid'
            }

            this.formVal.dni = '';
            if(this.persona.dni.length < 3){
                this.formVal.dni = 'is-invalid'; trabas++
            }else{
                this.formVal.dni = 'is-valid'
            }

            this.formVal.ciudad = '';
            if(this.persona.ciudad.length < 3){
                this.formVal.ciudad = 'is-invalid'; trabas++
            }else{
                this.formVal.ciudad = 'is-valid'
            }

            this.formVal.fecha = '';
            if(!this.persona.nacimiento){this.persona.nacimiento = new Date();}
            let alar = moment(this.persona.nacimiento);
            //console.log(moment().diff(alar, 'years'));
            if( moment().diff(alar, 'years') < 21){
                this.formVal.fecha = 'is-invalid'; trabas++
            }else{
                this.formVal.fecha = 'is-valid'
            }

            return trabas > 0
        },
        registrar(){
            
            if(this.validatePass && this.validateEmail && this.validateForm()){
                securityService.setNuevoUsuario(this.persona)
                    .then(response=>{
                        if(response.data.codigo === "OK"){
                            this.loginForm.usuario = this.persona.correo;
                            this.loginForm.contra = this.persona.password;
                            alert("REGISTRO CORRECTO, AHORA POR FAVOR INICIE SESION SON SU USUARIO (CORREO) Y CONTRASEÑA");
                            this.$emit('go', loginForm)
                        }else{
                            alert("Encontramos un error: "+response.data.mensaje);
                        }
                    })
                    .catch(()=>{
                        alert("Huston, tenemos un error");
                    })
            }else{
                alert("Por favor revisar el formulario")
            }
        },
    },

    watch:{
        'persona.password':function(){
            this.validatePass = this.validarPass();
        },
        cpass:function(){
            this.validatePass = this.validarPass();
        },
        'persona.correo':function(va){
            
            this.validateEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(va)
 
        }
    }
}
</script>

<style scope>
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
select {
    -webkit-appearance: auto;
}
.text-danger{
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
.is-invalid{
    border-color: #dc3545;
    padding-right: 3.4375rem;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem;
}
.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem;
}
</style>