<template>
  <v-app >
      <v-main class="blue lighten-4">
          <v-container fill-height fluid>
            <v-row 
                align="center"
                justify="center"
                style="max-width: 800px"
                class="mx-auto"
                >
                <v-col>
                    <div style="height: 24px;" class="d-block d-md-none"></div>
                    <v-card class="px-3" elevation="15" >
                        <v-row>
                            <v-col class="white--text blue-grey pt-5 px-4" >
                                <div class="d-none d-sm-block">
                                    <div class="text-center">
                                        <v-img class="mx-auto" max-width="100" src="@/assets/logo.png"></v-img> 
                                        <div class="text-h6">CLINICA GOOD HOPE</div>
                                    </div>
                                    <div class="mt-5 pb-3 text-justify">
                                        Queremos ayudarte aún si no puedes venir a nuestras sedes. Puedes iniciar sesión y si aun no estas registrado puedes hacerlos desde la pestaña de <b>registro</b>.
                                    </div>
                                </div>

                                <div class="d-block d-sm-none">
                                    <v-row>
                                        <v-col cols="3">
                                            <v-img class="mx-auto" max-width="40" src="@/assets/logo.png"></v-img> 
                                        </v-col>
                                        <v-col>
                                            <div style="font-size: 13px">
                                                Queremos ayudarte aún si no puedes venir a nuestras sedes
                                            </div>
                                        </v-col>
                                    </v-row>

                                </div>
                                
                            </v-col>
                            <v-col cols="12" md="8" class="blue-grey--text text-darken-4">
                                <div class="text-center mt-4 mb-4">
                                    <v-btn-toggle color="primary" mandatory  v-model="loginTag" class="mx-auto">
                                        <v-btn small>LOGIN</v-btn>
                                        <v-btn small>REGISTRO</v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div v-if="loginTag===0"  style="max-width: 20rem; margin:auto">
                                    <div class="my-4 py-2 text-h5">
                                        <v-row>
                                            <v-col md="2" ><v-btn icon @click="$router.push('/')"><v-icon>mdi-arrow-left</v-icon></v-btn></v-col>
                                            <v-col md="8" class="text-center">INGRESAR</v-col>
                                            <v-col md="2"></v-col>
                                        </v-row>
                                    </div>
                                    <div class="text-left">
                                        <div class="form-group">
                                            <div class="black--text"><b>Correo electrónico</b> <div class="text-muted">  (o DNI si es de Garantía de Salud)</div></div>
                                            <input type="text" name="user" id="user" class="form-control" placeholder="mi.correo@goodhope.org.pe" v-model="loginForm.usuario">
                                        </div>
                                        <div class="form-group mt-4">
                                            
                                            <div class="black--text font-weight-bold">Contraseña</div>
                                            <input @keypress.enter="loginUser()"  type="password" name="password" id="password" class="form-control" placeholder="mi contraseña" v-model="loginForm.contra">
                                        </div>
                                    </div>
                                    <div class="mt-3 error text-danger white--text text-center">{{errorLogin}}</div>
                                    <v-btn class="mt-3"  
                                        :disabled="loading"
                                        @click="loginUser()" 
                                        color="primary" width="100%">
                                        <v-progress-circular v-if="loading" indeterminate color="white" class="ma-2"></v-progress-circular>
                                        {{boton}}
                                    </v-btn>

                                    <div class="text-secondary mt-4">
                                        ¿No tienes una cuenta aún? <span class="text-primary cursor-pointer" @click="loginTag = 1">Regístrate aquí</span> 
                                    </div>
                                    <div class="text-secondary">
                                        ¿Olvidaste tu contraseña? <span class="text-primary cursor-pointer" @click="$router.push('/recover/solicitar')">Recuperar aquí</span> 
                                    </div>
                                </div>

                                <div v-else>

                                    <v-alert type="info" >
                                        Los afiliados de Garantía de de Salud ya están registrados
                                    </v-alert>
                                    <Registro @go="setLogin" />

                                </div>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      </v-main>
  </v-app>
</template>

<script>
import Registro from './Registro.vue'
import securityService from '@/service/hopeSeguridad'
export default {
    components: {Registro},
    data(){return{
        login:true,
        loginTag:0,
        boton:'iniciar mi experiencia',
        loading: false,
        errorLogin:'',
        loginForm:{
            usuario:'',
            contra: '',
            errorLogin:''
        },
        loaderTimer:{}
    }},
    methods:{
        loginUser(){
            clearTimeout(this.loaderTimer)
            this.loading = true;
            this.boton = "Validando credenciales"
            this.errorLogin = "";

            securityService.getToken(this.loginForm.usuario, this.loginForm.contra)
                .then(response => {
                    if(response.data.codigo === "OK"){
                        this.$store.commit('core/setToken', response.data.mensaje);
                        //localStorage.setItem('hope', token);
                        //console.log("Usuario authenticed");
                        securityService.getUsuario()
                        .then(response => {
                            console.log("Usuario obtenido");
                            this.$store.commit('core/setUser', response.data[0]);
                            this.$router.push("/app/bienvenido")
                            //this.$router.push(this.$store.state.login.redirect );
                            this.loading = false;
                            this.boton = "iniciar mi experiencia"
                        })
                        .catch((error)=>{
                            console.error("Error al contactar al servicio");
                            console.error(error);
                            this.loading = false;
                            this.boton = "iniciar mi experiencia"
                            this.errorLogin = "Usuario no identificado";
                            this.loaderTimer = setTimeout(() => {
                                this.errorLogin = "";
                            }, 5000);
                        })
                    }else{
                        this.loading = false;
                        this.boton = "iniciar mi experiencia"
                        this.errorLogin = "Usuario o contraseña no válidos";
                        this.loaderTimer = setTimeout(() => {
                                this.errorLogin = "";
                            }, 5000);
                    }
                })
                .catch(() => {
                    this.errorLogin = "Error al validar credenciales";
                    this.loading = false;
                    this.boton = "iniciar mi experiencia"
                    this.loaderTimer = setTimeout(() => {
                        this.errorLogin = "";
                    }, 5000);
                })

            //this.$router.push("/app/bienvenido")
        },
        setLogin(form){
            this.loginForm.usuario = form.usuario;
            this.loginForm.contra = form.contra;
        }
    }
}
</script>

<style scoped>
    .form-control{
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }.btn-primary {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }
    .w-100{
        width: 100%;
    }
    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .text-primary{
        font-weight: bold;
    }

</style>