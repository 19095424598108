export default class Persona {
    nombre = '';
    paterno = '';
    materno = '';
    sexo = 'M';
    dni = '';
    pais = 'PER';
    ciudad = '';
    religion = 'NO';
    nacimiento = null;
    correo = '';
    password = '';
    grado='0'

    constructor (){}
}